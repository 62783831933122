import CableReady from 'cable_ready'
import consumer from "./consumer"

if(document.querySelector('meta[name=shares-channel]') !== null){
  const channel_id = document.querySelector('meta[name=shares-channel]').content;

  console.log("connected shares channel-id: " + channel_id)

  consumer.subscriptions.create(
    { 
      channel: "SharesChannel", 
      id: channel_id
    }, 
    {
      connected () {
          console.log("shares_channel.js did connect channel_id: " + channel_id)
          this.send({ message: 'SharesChannel Client is live' })
      },
      received(data) {
        // console.log("shares_channel.js - received data on SharesChannel " + channel_id)
        // Called when there's incoming data on the websocket for this channel
        if (data.cableReady) CableReady.perform(data.operations)
      },
      disconnected () {
        console.log("You were disconnected from shares_channel " + channel_id)
      }
    }
  );
} else {
  
}