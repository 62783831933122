import CableReady from 'cable_ready'
import consumer from "./consumer"

if(document.querySelector('meta[name=boards-channel]') !== null){
  const channel_id = document.querySelector('meta[name=boards-channel]').content;
  consumer.subscriptions.create(
    { 
      channel: "BoardsChannel", 
      id: channel_id
    }, 
    {
      connected () {
          console.log("boards_channel.js for board " + channel_id + " did connect")
          this.send({ message: 'BoardsChannel Client ' + channel_id + ' is live' })
      },
      received(data) {
        // console.log("boards_channel.js - received " + channel_id + " data: " + Object.keys(data.operations))
        // Called when there's incoming data on the websocket for this channel
        if (data.cableReady) CableReady.perform(data.operations)
      },
      disconnected () {
        console.log("You were disconnected from boards channel " + channel_id)
      }
      
    }
  );
}